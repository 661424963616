import { inject, Injectable } from '@angular/core';
import { PostulationsApi, PostulationWithSessionAndResumeOrPublicationWithTriber } from '@tribuu-api';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { tap } from 'rxjs';
import { PostulationActions } from './postulations.actions';

export interface PostulationStateModel {
  items: { [key: string]: PostulationWithSessionAndResumeOrPublicationWithTriber };
  postulations: { [key: string]: PostulationWithSessionAndResumeOrPublicationWithTriber };
  loading: boolean;
  loaded: boolean;
}
const DEFAULT_STATE: PostulationStateModel = {
  items: {},
  postulations: {},
  loading: false,
  loaded: false,
};
@State<PostulationStateModel>({
  name: 'postulations',

  defaults: DEFAULT_STATE,
})
@Injectable()
export class PostulationState {
  private readonly postulationsAPI = inject(PostulationsApi);
  @Selector()
  static isLoading(state: PostulationStateModel) {
    return state.loading;
  }

  @Selector()
  static myPostulations(state: PostulationStateModel) {
    return Object.values(state.postulations);
  }

  @Selector()
  static isLoaded(state: PostulationStateModel) {
    return state.loaded;
  }

  @Selector()
  static postulations(state: PostulationStateModel) {
    return Object.values(state.items);
  }

  @Selector()
  static postulationsbyJob(state: PostulationStateModel) {
    return (uid: string) => {
      return Object.values(state.items).filter((p) => p.jobId === uid);
    };
  }

  @Action(PostulationActions.FetchAll)
  fetchAll(ctx: StateContext<PostulationStateModel>) {
    if (ctx.getState().loaded) return;

    ctx.patchState({ loading: true });
    return this.postulationsAPI.findAllAppliedByAuthUser().pipe(
      tap((postulations) => {
        const mapItems = postulations.reduce(
          (acc: { [key: string]: PostulationWithSessionAndResumeOrPublicationWithTriber }, p) => {
            return { ...acc, ...{ [p.uid]: p } };
          },
          {}
        );
        ctx.patchState({ postulations: mapItems, loading: false, loaded: true });
      })
    );
  }

  @Action(PostulationActions.Apply)
  apply(
    ctx: StateContext<PostulationStateModel>,
    { createPostulationDto, jobId }: PostulationActions.Apply
  ) {
    const postulations = ctx.getState().postulations;

    return this.postulationsAPI.createPostulation({ createPostulationDto, uid: jobId }).pipe(
      tap((postulation) => {
        return ctx.patchState({
          postulations: { ...postulations, [postulation.uid]: postulation },
        });
      })
    );
  }

  @Action(PostulationActions.Reset)
  reset(ctx: StateContext<PostulationStateModel>) {
    return ctx.setState(DEFAULT_STATE);
  }
}
